import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import FooterOne from '../components/FooterOne';
import NavbarThree from '../components/NavbarThree';
import ServiceAreaGroupThree from '../components/ServiceAreaGroupThree';

const ServiceThree = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavbarThree />

      {/* Navigation Bar */}
      <Breadcrumb title={'Services'} />

      {/* ServiceAreaGroupThree */}
      <ServiceAreaGroupThree />

      {/* Pricing Area One */}
      {/* <PricingAreaOne /> */}

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default ServiceThree;
